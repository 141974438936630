import fetchAPI from '../fetchAPI';

export const callRefreshToken = async (): Promise<
  iResponse<{ data: { RefreshToken: RefreshToken[] } }>
> => {
  const response: iResponse<{ data: { RefreshToken: RefreshToken[] } }> = await fetchAPI({
    method: `POST`,
    route: `core/refresh-token`,
    queryString: ``
  });

  // const {error} = response;
  // if (error) throw new ResponseError(error);

  return response;
};

export default callRefreshToken;
