import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { RouteComponentProps, navigate } from '@reach/router';
import { Button, Card, ReadMoreLess, TextView } from '@atoms';
import {
  callGetAllCompanyToken,
  callGetCustomValues,
  callRefreshToken,
  callSyncLocation
} from '@api';
import { EditNoteRounded } from '@mui/icons-material';
import BasicModal from './popup';
import { SelectBillingAccounts } from '@organisms';

const windowInnerHeight = window.innerHeight;

const CustomValues: React.FC<RouteComponentProps> = () => {
  const [ghlExpireToken, setGhlExpireToken] = useState<boolean>(false);
  const [customValues, setCustomValues] = useState<CustomValues[]>();
  const [accounts, setAccounts] = useState<CompanyToken[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<CompanyToken>();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateValues, setUpdateValues] = useState<CustomValues>();
  const [itemIndex, setIndex] = useState<number>(0);
  const [datasetagain, setDataAgain] = useState<boolean>(false);
  const [synclocation, setSyncLocation] = useState<boolean>(false);
  const [selectstatus, setSelectStatus] = useState<string>('live');
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, code } = await callGetAllCompanyToken({
          type: 'c',
          status: selectstatus,
          pageNumber: 1,
          pageSize: 10000
        });
        if (code === 200 && data) {
          const sortedData = data.data.sort((a, b) => a.companyName.localeCompare(b.companyName));

          setAccounts(sortedData);

          if (!selectedAccount && sortedData.length > 0) {
            setSelectedAccount(sortedData[0]);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectstatus]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.locationId) {
      setLoading(true);

      callGetCustomValues(selectedAccount.locationId)
        .then((res) => {
          if (res.code === 200) {
            setCustomValues(res.data?.data.customValues);
          }
          if (res.code === 400) {
            setGhlExpireToken(true);
          } else if (res.data?.data.code === 422) {
            setGhlExpireToken(true);
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedAccount, datasetagain]);

  const handleAccountClick = (item: CompanyToken) => {
    setSelectedAccount(item);
  };
  const handleCustomValues = (item: CustomValues, index: number) => {
    setUpdateValues(item);

    setIndex(index);
  };
  const handleChangestatus = (event: SelectChangeEvent) => {
    setSelectStatus(event.target.value);
  };

  const handleNext = () => {
    const foundValue = customValues && customValues.find((value, index) => index > itemIndex);

    if (foundValue) {
      setUpdateValues(foundValue);
      setIndex(itemIndex + 1);
    } else {
      console.log('No value found with index greater than itemIndex.');
    }
  };

  const handlePreview = () => {
    let lastValue = null;
    for (let index = 0; customValues && index < customValues.length && index < itemIndex; index++) {
      const value = customValues[index];

      lastValue = value;
    }

    if (lastValue) {
      setUpdateValues(lastValue);
      setIndex(itemIndex - 1);
    } else {
      console.log('No value found with index less than itemIndex.');
    }
  };

  const refreshtoken = () => {
    callRefreshToken().then((res) => {
      if (res.code === 200) {
        setGhlExpireToken(false);
        if (selectedAccount && selectedAccount.locationId) {
          setLoading(true);

          callGetCustomValues(selectedAccount.locationId)
            .then((res) => {
              if (res.code === 200) {
                setCustomValues(res.data?.data.customValues);
              }
              if (res.code === 400) {
                setGhlExpireToken(true);
              } else if (res.data?.data.code === 422) {
                setGhlExpireToken(true);
              } else if (res.code === 422) {
                navigate('/settings');
              }
            })
            .catch((e) => {
              console.log(e);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else {
        navigate('/settings');
      }
    });
  };

  const syncLocation = () => {
    setSyncLocation(true);
    callSyncLocation(selectedAccounts)
      .then((res) => {
        if (res.code == 200) {
          console.log(res.data);
          setSyncLocation(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Box m={4}>
        <Box sx={{ mt: 1, mb: 2, display: 'flex', justifyContent: 'space-between' }}>
          <TextView display={'initial'} $fontSize={28}>
            Custom Values
          </TextView>
          <Stack direction={'row'}>
            <Stack direction={'row'} ml={2} alignItems={'center'}>
              <TextView $fontWeight={400}  $fontSize={14}>
                Status Type
              </TextView>
              <Select
                value={selectstatus}
                onChange={handleChangestatus}
                displayEmpty
                size="small"
                sx={{ backgroundColor: '#ffffff', fontSize: 14, ml: 1, mr: 2 }}
                inputProps={{ 'aria-label': 'Without label' }}>
                <MenuItem key="all" value="b">
                  All
                </MenuItem>
                <MenuItem key="live" value="live">
                  Live
                </MenuItem>
                <MenuItem key="staging" value="staging">
                  Staging
                </MenuItem>
              </Select>
            </Stack>
            <Stack direction={'row'} ml={2} mr={2} alignItems={'center'}>
              <SelectBillingAccounts
                allSelected
                status={selectstatus}
                onSelectedAccounts={function (list: string[]): void {
                  setSelectedAccounts(list);
                }}
              />
            </Stack>
            <Button size="small" loading={synclocation} onClick={syncLocation}>
              Sync Location
            </Button>
          </Stack>
        </Box>
        <Divider />
        {loading && <LinearProgress />}
        {ghlExpireToken ? (
          <Box>
            <Grid container rowSpacing={1} columns={{ xs: 1, sm: 6, md: 12 }}>
              <Grid item xs={3}></Grid>
              <Grid item>
                <Card sx={{ mt: 2, pr: 3, pl: 3, height: 500, width: 600 }}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    style={{ height: '100%' }}>
                    <Grid item>
                      <TextView display={'initial'} $fontSize={26}>
                        Connect with GHL
                      </TextView>
                      <Divider />
                    </Grid>
                    <Grid item style={{ textAlign: 'center', padding: '20px' }}>
                      <TextView>
                        Your session may be expired or disconnected. Press Connect to reconnect.
                      </TextView>
                    </Grid>

                    <Grid item>
                      <Divider />
                      <Box
                        display={'flex'}
                        justifyContent={'space-evenly'}
                        alignItems={'center'}
                        paddingTop={'8px'}>
                        <Button size="small" variant="contained" onClick={refreshtoken}>
                          Connect
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid container mt={3} bgcolor={'#F6F5F5'} borderRadius={'16px'}>
            <Grid item xs={3} sm={4} md={4} lg={4} xl={3}>
              <nav
                style={{
                  height: windowInnerHeight - 20, // Adjust as needed
                  overflowY: 'auto',
                  backgroundColor: '#E2E5E6',
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  borderRadius: '16px'
                }}>
                <List sx={{ height: '100%' }}>
                  {accounts?.map((item) => (
                    <ListItem
                      key={item.companyName}
                      component="div"
                      selected={selectedAccount === item}
                      className={selectedAccount === item ? 'selected' : ''}
                      style={{
                        backgroundColor: selectedAccount === item ? '#086BDE' : 'inherit',
                        color: selectedAccount === item ? 'white' : 'inherit',
                        borderRadius: selectedAccount === item ? '10px' : '0px'
                      }}
                      onClick={() => handleAccountClick(item)}>
                      <ListItemText
                        primary={item.companyName}
                        primaryTypographyProps={{
                          fontSize: 16,
                          fontWeight: 600
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </nav>
            </Grid>
            <Grid item xs={9} sm={8} md={8} lg={8} xl={9} spacing={2}>
              <nav
                style={{
                  height: windowInnerHeight - 20, // Adjust as needed
                  overflowY: 'auto'
                }}>
               
                {loading ? (
                  <>
                    {customValues?.map((item, index) => (
                      <ListItem key={index}>
                        <ListItemText>
                          <TextView variant="h5">
                            <Skeleton animation="wave" />
                          </TextView>
                        </ListItemText>
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <List>
                    {customValues?.map((item, index) => (
                      <ListItem key={item.fieldKey}>
                        <ListItemText
                          primary={item.name}
                          secondary={<ReadMoreLess text={item?.value} maxLength={455} />}
                          primaryTypographyProps={{
                            fontSize: 18,
                            fontWeight: 550
                          }}
                          secondaryTypographyProps={{
                            fontSize: 14,
                            fontFamily: 'Arial',
                            fontWeight: 400
                          }}
                        />

                        <ListItemIcon>
                          <EditNoteRounded
                            onClick={() => {
                              handleCustomValues(item, index);
                              setDataAgain(false);
                            }}
                          />
                        </ListItemIcon>
                      </ListItem>
                    ))}
                  </List>
                )}
                <BasicModal
                  initialValues={updateValues}
                  status={selectstatus}
                  onClose={() => {
                    setUpdateValues(undefined);
                    setDataAgain(true);
                  }}
                  handleNextindex={() => {
                    handleNext();
                  }}
                  handlePreview={() => {
                    handlePreview();
                  }}
                />
              </nav>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default CustomValues;
