import React, { useContext, useEffect } from 'react';
import { Router, RouteComponentProps, LocationProvider, navigate } from '@reach/router';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/errorBoundary';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import {
  AdminTemplates,
  Errors404,
  AdminDashboard,
  AdminAccount,
  AdminTeam,
  SADashboard,
  SAMarkupMargin,
  SAAllAccount,
  SALogin,
  BillingDetails,
  Expired,
  SALocations,
  SALocationTokens,
  SALocationTransactionsLogs,
  SAAnalytics,
  SASettings,
  TaskReport,
  SAOAuthGhl,
  SACustomValues
} from './pages';

import { ApplicationContext, ApplicationState, TableState } from './particles/context';
import { Admin, SuperAdmin } from './templates';
import { ThemeWrapper, routes } from '@particles';
import { callGetUser } from './particles/api/user';

export interface AppWrapperProps extends RouteComponentProps {
  /** The page contents */
  children?: React.ReactElement | React.ReactElement[];
}

const AppWrapper: React.FC<AppWrapperProps> = (props: AppWrapperProps) => {
  const { children, location } = props;
  const pathname = location?.pathname ?? '';

  const {
    state: { user },
    dispatch
  } = useContext(ApplicationContext);

  useEffect(() => {
    if (pathname.startsWith('/admin')) {
      document.title = 'SalesRook Admin Portal';
    } else {
      document.title = 'SalesRook SuperAdmin Portal';
      if (!user) {
        if (!pathname.includes('/login')) {
          navigate('/login');
        }
      }
    }

    if (user) {
      callGetUser({ email: user.email ?? '' }).then((value) => {
        if (value.code === 200) {
          dispatch({ key: 'user', value: value.data, type: 'set' });
        } else if (value.code === 403) {
          localStorage.clear();
          if (user.role.name === 'Super Admin') {
            navigate('/login');
          } else {
            navigate('/expired');
          }
          dispatch({ key: 'user', value: undefined, type: 'set' });
        }
      });
    }
  }, [props]);

  return (
    <ErrorBoundary>
      <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
    </ErrorBoundary>
  );
};

const AdminLogin: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const { email, token } = props;
    if (email && token) {
      localStorage.setItem('token', token);
      localStorage.setItem('email', email);

      callGetUser({ email }).then((value) => {
        if (value.code === 200) {
          dispatch({ key: 'user', value: value.data, type: 'set' });
          localStorage.setItem('user', JSON.stringify(value.data));

          setTimeout(() => {
            navigate('/admin');
          }, 2000);
        }
      });
    }
  }, [props]);

  const { dispatch } = useContext(ApplicationContext);

  return <div>Signing ... </div>;
};

const App: React.FC = () => (
  <LocationProvider>
    <ApplicationState>
      <TableState>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ThemeWrapper>
            <Router basepath="/">
              <AppWrapper path="/">
                <AdminLogin path="/admin/:token/:email" />
                <Admin path="/admin">
                  <AdminDashboard path="/" />
                  <AdminAccount path="/account" />
                  <AdminTeam path="/team" />
                  <AdminTemplates path="/templates" />
                  <BillingDetails type="admin" path="/billing" />
                </Admin>
                <SuperAdmin path="/">
                  <SADashboard path={`${routes.SA_Dashboard}`} />
                  <SAAllAccount path={`${routes.SA_Negotiator}`} />
                  <SALocations path={`${routes.SA_BillingAccounts}`} />
                  <SALocationTransactionsLogs path={`${routes.SA_UsageHistory}`} />
                  <SAMarkupMargin path={`${routes.SA_MarkupMargins}`} />
                  <SAAnalytics path={`${routes.SA_Analytics}`} />
                  <SASettings path={`${routes.SA_Settings}`} />
                  <BillingDetails type="superadmin" path={`${routes.SA_Negotiator}/:id`} />
                  <SALocationTokens
                    path={`${routes.SA_BillingTokens}/:companyTokenId`}
                    type={'superadmin'}
                  />
                  <SACustomValues path={`${routes.SA_CustomValues}`} />
                </SuperAdmin>

                <SALogin path="/login" />
                <Errors404 path="/404" />
                <Expired path="/expired" />
              </AppWrapper>
              <SAOAuthGhl path={`${routes.SA_OAuthGhl}`} />
              <TaskReport path="/task-report/:locationId" />
              <SALocationTokens path="/location-tokens/:locationId" type={'superadmin'} />
            </Router>
            <ToastContainer />
          </ThemeWrapper>
        </LocalizationProvider>
      </TableState>
    </ApplicationState>
  </LocationProvider>
);

export default App;

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
