import React, { useMemo, useState, useContext } from 'react';
import {
  useTable,
  useSortBy,
  useExpanded,
  usePagination,
  useRowSelect,
  TableInstance,
  HeaderProps,
  CellProps,
  Column,
  TableToggleAllRowsSelectedProps,
  TableToggleRowsSelectedProps
} from 'react-table';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import MaterialTable from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';

import { Alert, ErrorAlert } from '../../atoms';

import StyledTable from './table.style';
import { TableBody, TableCell, TableHeader, TablePagination } from './partials';
import { TableContext } from '@particles';

export interface TableProps {
  name: TableType;
  /** Table columns */
  columns: TableColumn[];
  /** Table data */
  data: TableRow[];
  /** Table pagination data, pagination will be disabled if not provided */
  pagination?: Pagination;
  /** If true, the API request is still awaiting results */
  isLoading: boolean;
  /** The error returned by the API, if there is one */
  error?: Error | null;
  /** If true, the rows will be selectable */
  selectable?: boolean;
  /** Removes the ability to change page size */
  disablePageSize?: boolean;
  /** If true, switches from normal row style to card style */
  cards?: boolean;
  /** If true, switches from normal row style to card style */
  noResultsMessage?: string;
}

export const Table: React.FC<TableProps> = ({
  name,
  columns,
  data,
  pagination,
  isLoading,
  error,
  selectable,
  disablePageSize,
  cards = false,
  noResultsMessage
}: TableProps) => {
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const {
    state: {
      allAccountPagination,
      teamPagination,
      billingPagination,
      accountPerformancePagination,
      companyTokenPagination,
      openAiPagination,
      twilioPagination,
      srtPagination,
      companyTransactionsLogsPagination,
      apiKeys
    }
  } = useContext(TableContext);

  let pageSize = 10;

  switch (name) {
    case 'all_account':
      pageSize = allAccountPagination.perPage;
      break;
    case 'team':
      pageSize = teamPagination.perPage;
      break;
    case 'billing':
      pageSize = billingPagination.perPage;
      break;
    case 'account_performance':
      pageSize = accountPerformancePagination.perPage;
      break;
    case 'company-token':
      pageSize = companyTokenPagination.perPage;
      break;
    case 'open-ai':
      pageSize = openAiPagination.perPage;
      break;
    case 'twilio':
      pageSize = twilioPagination.perPage;
      break;
    case 'srt':
      pageSize = srtPagination.perPage;
      break;
    case 'company-transactions-logs':
      pageSize = companyTransactionsLogsPagination.perPage;
      break;
    case 'api-keys':
      pageSize = apiKeys.perPage;
      break;
  }

  // Adds the page size prop or query value to the list of available options
  const rowsPerPageOptions = [...new Set([5, 10, 25, 50, 100])].sort((a, b) => a - b);

  // Loops through the provided columns and sets the custom table cell
  const customCellColumns = useMemo(
    () => columns.map((col) => ({ ...col, Cell: TableCell })),
    [columns]
  );

  // Creates table instance
  const tableInstance: TableInstance<TableRow> = useTable(
    { columns: customCellColumns, data, initialState: { pageSize: pageSize } },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((hookColumns) => {
        const arr: Column<TableRow>[] = hookColumns;

        if (selectable) {
          arr.unshift({
            id: `_selector`,
            Header: function RenderCheckbox({
              getToggleAllRowsSelectedProps
            }: HeaderProps<TableRow>) {
              return <CellCheckbox {...getToggleAllRowsSelectedProps()} />;
            },
            Cell: function RenderCheckbox({ row }: CellProps<TableRow>) {
              return <CellCheckbox {...row.getToggleRowSelectedProps()} />;
            }
          });
        }
        return arr;
      });
    }
  );
  const { columns: finalColumns, getTableProps } = tableInstance;

  return (
    <Box>
      <StyledTable $headerHeight={headerHeight}>
        <TableContainer {...(cards && { className: `table-cards` })}>
          {cards && <div className="header-background" />}
          <MaterialTable
            sx={{ border: '1px solid rgba(36, 36, 35, 0.32)', tableLayout: 'fixed' }}
            {...getTableProps()}
          >
            <TableHeader
              name={name}
              tableInstance={tableInstance}
              setHeaderHeight={setHeaderHeight}
            />
            {!!error && <TableError error={error} colSpan={finalColumns.length} />}
            {!error && (
              <TableBody pageSize={pageSize} tableInstance={tableInstance} isLoading={isLoading} />
            )}
            {!data.length && !error && !isLoading && (
              <TableNoResults colSpan={finalColumns.length} noResultsMessage={noResultsMessage} />
            )}
          </MaterialTable>
        </TableContainer>
        <Box flex={1} />
      </StyledTable>
      <Grid container justifyContent="flex-end">
        {pagination && (
          <TablePagination
            name={name}
            tableInstance={tableInstance}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination={pagination}
            disablePageSize={disablePageSize}
          />
        )}
      </Grid>
    </Box>
  );
};

const CellCheckbox = (rowProps: TableToggleAllRowsSelectedProps | TableToggleRowsSelectedProps) => (
  <Checkbox
    onClick={(e) => {
      e.stopPropagation();
    }}
    color="primary"
    {...rowProps}
  />
);

interface TableErrorProps {
  /** The error returned by the API, if there is one */
  error: Error;
  /** The number of columns in the table */
  colSpan: number;
}

const TableError: React.FC<TableErrorProps> = ({ error, colSpan }: TableErrorProps) => {
  const alertTitle = `There was an error loading the table data. If this problem persists, please contact the support team.`;

  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <Box m={3}>
            <ErrorAlert variant="outlined" title={alertTitle} error={error} />
          </Box>
        </td>
      </tr>
    </tbody>
  );
};

interface TableNoResultsProps {
  /** The number of columns in the table */
  colSpan: number;
  /** If true, switches from normal row style to card style */
  noResultsMessage?: string;
}

const TableNoResults: React.FC<TableNoResultsProps> = ({
  colSpan,
  noResultsMessage
}: TableNoResultsProps) => {
  const message = noResultsMessage ?? `There were no results returned for this query`;

  return (
    <tbody>
      <tr>
        <td colSpan={colSpan}>
          <Box m={3}>
            <Alert severity="info" variant="outlined" title="No results" message={message} />
          </Box>
        </td>
      </tr>
    </tbody>
  );
};

export default Table;
