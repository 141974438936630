import React from 'react';
import {  Grid } from '@mui/material';
import { ReactComponent as IconTeam } from '@assets/svg/team.svg';
import { ReactComponent as IconLicensed } from '@assets/svg/licensed.svg';
import { ReactComponent as IconActive } from '@assets/svg/active.svg';
import { CommonCard } from '@organisms';

interface CardListProps {
  accounts: number;
  licensed: number;
  active: number;
}

const CardList: React.FC<CardListProps> = ({ accounts, licensed, active }: CardListProps) => {
  return (
    <Grid container columnSpacing={4} mt={3}>
      <Grid item sm={12} md={4}>
        <CommonCard title="Total Accounts" count={accounts} icon={<IconTeam />} bgColor="#CFDBD5" />
      </Grid>
      <Grid item sm={12} md={4}>
        <CommonCard
          title="Total Active Licences"
          count={licensed}
          icon={<IconLicensed />}
          bgColor="#086BDE"
          fontColor="#ffffff"
        />
      </Grid>
      <Grid item sm={12} md={4}>
        <CommonCard title="Total Active Users" count={active} icon={<IconActive />} />
      </Grid>
    </Grid>
  );
};

export default CardList;
