const columns: TableColumn[] = [
  {
    Header: `Account`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Total Licences`,
    accessor: `users`,
    dbKey: `users`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Activated Licenses`,
    accessor: `active`,
    dbKey: `active`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Total Conversations Synced`,
    accessor: `syned`,
    dbKey: `syned`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  },
  {
    Header: `Messages Sent`,
    accessor: `sent`,
    dbKey: `sent`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Messages Received`,
    accessor: `received`,
    dbKey: `received`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Average Messages Per User`,
    accessor: `average`,
    dbKey: `average`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
