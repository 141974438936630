import React, { useContext } from 'react';
import { TableInstance } from 'react-table';
import MaterialPagination from '@mui/material/TablePagination';
import { TableContext } from '@particles';

//import {TableContext} from 'context';

export interface TableHeadProps {
  name: TableType;
  /** Table instance */
  tableInstance: TableInstance<TableRow>;
  /** Table pagination data, pagination will be disabled if not provided */
  pagination: Pagination;
  /** Rows per page options */
  rowsPerPageOptions: number[];
  /** Removes the ability to change page size */
  disablePageSize?: boolean;
}

export const TablePagination: React.FC<TableHeadProps> = ({
  name,
  tableInstance,
  pagination,
  rowsPerPageOptions,
  disablePageSize
}: TableHeadProps) => {
  const { setPageSize } = tableInstance;
  const {
    dispatch,
    state: {
      allAccountPagination,
      teamPagination,
      billingPagination,
      accountPerformancePagination,
      companyTokenPagination,
      openAiPagination,
      twilioPagination,
      srtPagination,
      companyTransactionsLogsPagination,
      apiKeys
    }
  } = useContext(TableContext);
  const { pageNumber: currentPage, pageSize: perPage, totalCount: total } = pagination;

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ): void => {
    if (name === 'all_account') {
      dispatch({
        type: `set`,
        key: `allAccountPagination`,
        value: { page: newPage + 1, perPage: allAccountPagination.perPage }
      });
    } else if (name === 'team') {
      dispatch({
        type: `set`,
        key: `teamPagination`,
        value: { page: newPage + 1, perPage: teamPagination.perPage }
      });
    } else if (name === 'billing') {
      dispatch({
        type: `set`,
        key: `billingPagination`,
        value: { page: newPage + 1, perPage: billingPagination.perPage }
      });
    } else if (name === 'account_performance') {
      dispatch({
        type: `set`,
        key: `accountPerformancePagination`,
        value: { page: newPage + 1, perPage: accountPerformancePagination.perPage }
      });
    } else if (name === 'company-token') {
      dispatch({
        type: `set`,
        key: `companyTokenPagination`,
        value: { page: newPage + 1, perPage: companyTokenPagination.perPage }
      });
    } else if (name === 'open-ai') {
      dispatch({
        type: `set`,
        key: `openAiPagination`,
        value: { page: newPage + 1, perPage: openAiPagination.perPage }
      });
    } else if (name === 'twilio') {
      dispatch({
        type: `set`,
        key: `twilioPagination`,
        value: { page: newPage + 1, perPage: twilioPagination.perPage }
      });
    } else if (name === 'srt') {
      dispatch({
        type: `set`,
        key: `srtPagination`,
        value: { page: newPage + 1, perPage: srtPagination.perPage }
      });
    } else if (name === 'company-transactions-logs') {
      dispatch({
        type: `set`,
        key: `companyTransactionsLogsPagination`,
        value: { page: newPage + 1, perPage: companyTransactionsLogsPagination.perPage }
      });
    } else if (name === 'api-keys') {
      dispatch({
        type: `set`,
        key: `apiKeys`,
        value: { page: newPage + 1, perPage: apiKeys.perPage }
      });
    }
  };

  const handleChangeRowsPerPage: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (event) => {
    if (name === 'all_account') {
      dispatch({
        type: `set`,
        key: `allAccountPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'team') {
      dispatch({
        type: `set`,
        key: `teamPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'billing') {
      dispatch({
        type: `set`,
        key: `billingPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'account_performance') {
      dispatch({
        type: `set`,
        key: `accountPerformancePagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'company-token') {
      dispatch({
        type: `set`,
        key: `companyTokenPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'open-ai') {
      dispatch({
        type: `set`,
        key: `openAiPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'twilio') {
      dispatch({
        type: `set`,
        key: `twilioPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'srt') {
      dispatch({
        type: `set`,
        key: `srtPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'company-transactions-logs') {
      dispatch({
        type: `set`,
        key: `companyTransactionsLogsPagination`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    } else if (name === 'api-keys') {
      dispatch({
        type: `set`,
        key: `apiKeys`,
        value: { page: 1, perPage: Number(event.target.value) }
      });
    }
    setPageSize(Number(event.target.value));
  };

  return (
    <MaterialPagination
      component="div"
      rowsPerPageOptions={disablePageSize ? [] : rowsPerPageOptions}
      colSpan={3}
      count={total}
      rowsPerPage={perPage}
      page={currentPage - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}

      // ActionsComponent={TablePaginationActions}
    />
  );
};

export default TablePagination;
