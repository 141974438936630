const columns: TableColumn[] = [
  {
    Header: `Account`,
    accessor: `name`,
    dbKey: `name`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Active Licences`,
    accessor: `users`,
    dbKey: `users`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Active Users`,
    accessor: `active`,
    dbKey: `active`,
    isString: false,
    isNumeric: true,
    isDate: false,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Paid Till Date`,
    accessor: `endDate`,
    dbKey: `endDate`,
    isString: false,
    isNumeric: false,
    isDate: true,
    isBool: false,
    isSortable: true
  },
  {
    Header: `Status`,
    accessor: `status`,
    dbKey: `status`,
    isString: true,
    isNumeric: false,
    isDate: false,
    isBool: false,
    isSortable: false
  }
];

export default columns;
