import { DateTime } from 'luxon';
import { routes } from '@particles';

const generateRows = (licenses: License[]): TableRow[] =>
  licenses.map((license) => {
    const endDate = license.endDate;
    return {
      name: license.name,
      users: license.users,
      active: license.active,
      endDate: endDate ? DateTime.fromISO(endDate).toFormat('dd LLLL yyyy') : '',
      status: license.status,
      linkData: {
        to: `${routes.SA_Negotiator}/${license.companyId}`
      }
    };
  });

export default generateRows;
