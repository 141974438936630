const routes = {
  adminDashboard: `/admin`,
  adminTeam: `/admin/team`,
  adminTemplates: `/admin/templates`,
  adminAccount: `/admin/account`,
  adminBilling: `/admin/billing`,

  SA_Negotiator: `/negotiator`,
  SA_MarkupMargins: '/markup-margins',
  SA_Analytics: '/analytics',
  SA_BillingAccounts: `/billings`,
  SA_BillingTokens: `/billing-tokens`,
  SA_UsageHistory: `/usage-history`,
  SA_Settings: `/settings`,
  SA_OAuthGhl: `/oauth/core`,
  SA_Dashboard: `/`,
  SA_CustomValues: `/customvalues`,
  logout: `/logout`
};

const tableDefaults: TableDefaults = {
  perPage: 10,
  maxPerPage: 100,
  orderBy: ``
};

export { routes, tableDefaults };
