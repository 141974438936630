import fetchAPI from '../fetchAPI';

interface callGetAccountPerformanceProps {
  from: string;
  to: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
}

export const callGetAccountPerformance = async ({
  from,
  to,
  pageNumber,
  pageSize,
  sortBy
}: callGetAccountPerformanceProps): Promise<iResponse<Pagination<AccountPerformance[]>>> => {

  const queryParams = new URLSearchParams();
  queryParams.append('from', from);
  queryParams.append('to', to);
  if (pageNumber) {
    queryParams.append('pageNumber', pageNumber.toString());
  }
  if (pageSize) {
    queryParams.append('pageSize', pageSize.toString());
  }
  if (sortBy) {
    queryParams.append('sortBy', sortBy);
  }

  const queryString = queryParams.toString();

  const response: iResponse<Pagination<AccountPerformance[]>> = await fetchAPI({
    method: `GET`,
    route: `crm/messages/account-performance`,
    queryString: queryString
  });

  return response;
};

export default callGetAccountPerformance;
