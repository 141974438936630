import * as React from 'react';
import Box from '@mui/material/Box';
import { columns, generateRows } from './partials';
import { Table } from '@organisms';

interface AllAccountTableProps {
  licenses?: Pagination<LicenseCurrent>;
  isLoading: boolean;
}

const AllAccountTable: React.FC<AllAccountTableProps> = (props: AllAccountTableProps) => {
  const { licenses, isLoading } = props;
  const rowData: TableRow[] = licenses ? generateRows(licenses.data.accountDetails) : [];

  return (
    <Box sx={{ width: '100%', mt: 4 }}>
      <Table
        name="all_account"
        columns={columns}
        data={rowData}
        isLoading={isLoading}
        pagination={licenses}
        // error={error}
        // selectable
      />
    </Box>
  );
};

export default AllAccountTable;
