import { DateTime } from 'luxon';

const generateRows = (
  agents: Agent[],
  onSwitch: (
    agent: Agent,
    value: unknown,
    type: 'sync' | 'status' | 'edit' | 'delete' | 'recordType'
  ) => void,
  isSuperAdmin: boolean,
  user?: User
): TableRow[] =>
  agents.map((agent) => {
    const status: TableCellSwitch = {
      type: `switch`,
      data: {
        enable: 'Active',
        disable: 'Inactive',
        selected: agent.active,
        linkData: {
          onClick: (value) => {
            onSwitch(agent, value as boolean, 'status');
          }
        }
      }
    };

    const sync: TableCellSwitch = {
      type: `switch`,
      data: {
        enable: 'On',
        disable: 'Off',
        selected: agent.autoChatSync,
        linkData: {
          onClick: (value) => {
            onSwitch(agent, value as boolean, 'sync');
          }
        }
      }
    };

    const recordType: TableCellSelector = {
      type: `selector`,
      data: {
        selected: agent.preferredRecordType,
        linkData: {
          onClick: (value) => {
            onSwitch(agent, value as string, 'recordType');
          }
        }
      }
    };

    const edit: TableCellAction = {
      type: `action`,
      data: [
        {
          type: 'edit',
          linkData: {
            onClick: (value) => {
              onSwitch(agent, value as boolean, 'edit');
            }
          }
        }
      ]
    };

    const deleteAction: any = {
      type: 'delete',
      linkData: {
        onClick: (value: unknown) => {
          onSwitch(agent, value as boolean, 'delete');
        }
      }
    };

    const payload: TableRow = {
      name: agent.name ?? '',
      email: agent.email ?? '',
      phone: agent.phone ?? '',
      team: agent.team?.teamName ?? '-',
      active: status,
      autoChatSync: sync,
      preferredRecordType: recordType,
      createdAt: DateTime.fromISO(agent.createdAt).toFormat('dd/MM/yyyy')
    };

    if (isSuperAdmin) {
      edit.data.push(deleteAction);
      payload.edit = edit;
    } else {
      console.log('user', user);
      if (user?.role.name === 'Admin' || user?.role.name === 'Super Admin') {
        edit.data.push(deleteAction);
        payload.edit = edit;
      }
    }

    return payload;
  });

export default generateRows;
